.rbc-event.event-azure {
    background-color: #1e4db7;
  }
  
  .rbc-event.event-default {
    background-color: #1a97f5;
  }
  .rbc-event.event-red {
    background-color: #fc4b6c;
  }
  .rbc-event.event-green {
    background-color: #00ab55;
  }
  .rbc-event.event-warning {
    background-color: #fdd43f;
  }
  .rbc-off-range-bg {
    background-color: #d2d2d20d;
  }
  .darkbg .rbc-today {
    background-color: #8fbece;
  }
  .darkbg .rbc-month-row + .rbc-month-row,
  .darkbg .rbc-day-bg + .rbc-day-bg,
  .darkbg .rbc-header,
  .darkbg .rbc-month-view,
  .darkbg .rbc-toolbar button,
  .darkbg .rbc-time-content > * + * > * {
    border-color: #eaeaea57;
  }
  .rbc-event:focus,
  .rbc-day-slot .rbc-background-event:focus {
    outline: none;
  }
  .darkbg .rbc-toolbar button {
    color: rgba(255, 255, 255, 0.5);
  }
  .darkbg .rbc-toolbar button:active,
  .darkbg .rbc-toolbar button.rbc-active {
    background-color: rgba(0, 0, 0, 0.5);
    border-color: #d2d2d20d;
  }
  .form-control {
    height: 50px;
    line-height: 40px;
    font-size: 18px;
    width: 100%;
    border-width: 1px;
    padding: 5px;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .btn {
    padding: 10px 15px;
    box-shadow: none !important;
    border-radius: 5px;
    text-decoration: none;
    color: white;
  }
  .btn-primary {
    background-color: #2b2b2b;
  }
  .btn-secondary {
    background-color: #1a97f5;
  }
  
  @media (max-width: 767px) {
    .rbc-btn-group {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }
    .rbc-toolbar .rbc-toolbar-label {
      margin-bottom: 15px;
      display: block;
    }
    .rbc-calendar {
      height: 100vh !important;
    }
  }
  
  .rbc-calendar {
    min-height: 600px;
  }
  /* .appointmentForm input{
    background-color: white !important;
    border-radius: 5px !important;  
    border: 1px solid #A2A2A2 !important;
  } */
  .appointmentForm .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select {
    background-color: white !important;
    border-radius: 5px !important;  
    border: 1px solid #A2A2A2 !important;
  }
  .appointmentForm .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root{
    background-color: white !important;
  }
  .appointmentForm input:focus-visible,   .appointmentForm input:hover,   .appointmentForm input:focus-visible{
    outline: none ;
}
.appointmentForm.MuiDialogContent-root, .appointmentFormActions.MuiDialogActions-root {
  background: #EBF5FF;
}

.rbc-row-segment{
  padding: 1px 6px;
}

.rbc-toolbar-label{
  color: #1990FC;
  font-weight: 500;
font-size: 23px;
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active,
.rbc-toolbar button:hover, .rbc-toolbar button:focus{
  background: #1990FC;
  color: white;
  box-shadow: none;

}

.rbc-header{
  color: #1990FC;
 }
.rbc-month-view{
border: none;
}
.rbc-month-view .rbc-header{
   border-left: none !important;
}
.rbc-month-row{
  border: 1px solid #AFAFAF;
  }
  .rbc-off-range-bg{
    background: #F2F6F9;
  }
  .rbc-date-cell{
    text-align: center;
  }

  .rbc-date-cell.rbc-now > a{
border-radius: 50%;
background: #1990FC ;
color: white;
padding: 2px 4px;
font-size: 11px;
  }
  .rbc-date-cell>a{
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;

  }
  .rbc-agenda-view table.rbc-agenda-table{
    color: white;
  }

 

  .rbc-events-container>.rbc-event {
    flex-direction: column !important;
  }
  
  /* .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
    flex-direction: row !important;
  } */

  .rbc-events-container>.rbc-event>.rbc-event-content{
    /* display: flex; */
    /* gap: 5px; */
    font-size: 10px;
  }

  .rbc-row-segment>.rbc-event>.rbc-event-content{ 
    /* Month view */
    font-size: 10px;
    text-align: center;
    display: inline;
  }
  .rbc-event-content>div{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rbc-agenda-event-cell> div{
    display: inline;
    margin-left: 5px;
  }
  table.rbc-agenda-table tbody > tr + tr,
  .rbc-agenda-view table.rbc-agenda-table {
    /* background-color: white !important; */
    border: none !important;
   }
   .rbc-agenda-view .rbc-agenda-event-cell{
    /* background-color: white !important; */
    border-radius: 13px !important;
    border: 5px solid white !important;
   }
  .rbc-agenda-time-cell, .rbc-agenda-date-cell{
    background: white !important;
    color: black;
    border: 5px solid white !important;
    padding-left: 0px !important;

  }
  .rbc-agenda-table>thead{
    display: none;
  }
  .rbc-agenda-content{
margin-top: 30px;
  }

  .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell{
    font-weight: 700;
font-size: 14px;
 color: #000000;
 text-transform: uppercase;
 /* list-style-type: circle; */
 display: list-item;
 list-style: inside;
}
.rbc-agenda-time-cell::marker{
  color: #1990FC; 
  /* font-size: 1.8em; */
/* line-height:1.2em; */
/* text-align: end !important; */
}
  .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-date-cell{
    font-weight: 700;
font-size: 18px;
color: #1990FC;
  }

  .rbc-event-label {
    /* time fontsize */
    font-size: 65%;
}

.appointmentFormActions.MuiDialogActions-root{
  justify-content: space-between;
  padding: 8px 24px;
}

@media (max-width: 515px){
  .appointmentFormActions.MuiDialogActions-root{
    flex-direction: column;
    gap:10px;
  }
  .appointmentFormActions .MuiButtonBase-root.MuiButton-root{
    padding: 5px;
  }
}