.modalMainClass{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-width: 300px;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: fixed;
    width: 100%;
    z-index: 1200;
    background:rgb(16 17 18 / 30%);

}
img.logoImg{
    width: 70%;
    max-width: 200px;
      margin: 10px auto;
      display: flex;     
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
 
input[type=number] {
    -moz-appearance:textfield;
}

.MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-input.Mui-disabled{
    background-color: white;
}
.MuiButtonBase-root.MuiListItem-root.Mui-selected{
    background-color: #1990FC !important;
}

.MuiPaper-root.MuiAppBar-root{
    background-color: white;
    padding-left: 0;
}
.MuiPaper-root.MuiDrawer-paper>.scrollbar-container,
.MuiPaper-root.MuiDrawer-paper>.MuiBox-root{
    background: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 0px;
    gap: 20px;
    margin: 10px 10px 10px 30px;
}

.MuiPaper-root.MuiDrawer-paper{
    z-index: 100;
/* box-shadow: none; */
}
.MuiBreadcrumbs-separator{
    color: #1990FC;
}

/* table rows on hover */
.MuiTableRow-root.MuiTableRow-hover:hover{
    background-color: rgba(79, 159, 233, 0.1) !important;
    border-bottom: 1px solid #D1D1D1;
}
.MuiTableCell-head.MuiTableCell-root > span > h6{
    /* padding: 10px !important; */
    min-width: fit-content !important;
}
.MuiButtonBase-root.MuiMenuItem-root:hover{
    background-color: rgba(79, 159, 233, 0.1);
}

h6.MuiTypography-root{
    word-break: break-word;
    /* text-align: justify; */
}
td h6.MuiTypography-root{
    word-break: break-word;
    text-align: left;
}
.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select{
    padding: 16.5px 14px;
        /* padding in select list */
}

.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: rgba(0, 0, 0, 1);
}

.chooseHospitalDiv{
    width: 200px;
    float: right;
} 
.childrenBox{
    display: block !important;
} 
.chooseHospitalDiv .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select{
    text-overflow: ellipsis;
    padding-right: 25px;
}
.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input{
    padding: 2.5px 45px 2.5px 6px !important;
}
@media(max-width: 900px){
    .MuiPaper-root.MuiDrawer-paper>div{
        margin: 30px;
    }
    img.logoImg{
        margin: 30px 0 30px 0; 
    }
    .mainSidebarSm  img.logoImg{
        width: 55% !important;
        margin: 30px auto;
    }
}

@media(min-width: 700px){
   .chooseHospitalDiv{
    width: 300px;
     padding: 12px 0;
} 
}

@media(min-width: 1200px){   
 .MuiPaper-root.MuiDrawer-paper>.scrollbar-container,
 .MuiPaper-root.MuiDrawer-paper>.MuiBox-root{
     margin: 110px 10px 10px 30px; 
 }
}

.form-select {
    color: #000;
    font-size: 16px;
    padding: 22px 20px;
    background-color: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 100px;
}


.d-flex {
    display: flex;
}
.align-items-center{
    align-items: center;
}
 .justify-content-between{
    justify-content: space-between;
 }

 .w-100{
    width: 100% !important;
 }


 .btn-link{
    white-space: nowrap;
    color: #1990FC;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #464646;
 }

 .panel-subHeading{
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24px;
    color: 464646;
 }


 .me-12{
    margin-right: 12px !important;
 }
 .me-2{
    margin-right: 8px !important;
 }
.p-0{
    padding: 0 !important;
}

.css-13cymwt-control,.css-t3ipsp-control{
    height: 58px;
    border-radius: 4px;
    /* border: 1px solid #747474 !important; */
}

.form-select:hover> div {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

 .tabpanelContent .form-select input{
    border: none !important;
    background-color: transparent !important;
}

.border-0{
    border: none;
}

.css-13cymwt-control>div{
    padding-left:12px ;
    padding-right: 12px;
}

.modal .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    top: 0px;
}

.tabpanelContent input input{
    border: 1px solid #a2a2a2!important;
}
.tabpanelContent input:hover{
    border-color: rgba(0, 0, 0, 0.87) !important;
}
/* 
.patientDetailPage .MuiCardContent-root input{
    border: 1px solid #dee3e9 !important;
} */



.MuiFormControl-root{
    /* border: 1px solid #dee3e9 !important; */
    border-radius: 4px;
}
/* .MuiFormControl-root:hover, */
.patientDetailPage .MuiCardContent-root input:hover{
    border-color: rgba(0, 0, 0, 0.87) !important;
}
.MuiFormControl-root fieldset{
    border: none !important;
    height: 56px;
}


.css-12f6v3e:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.87);
}

.border-g1{
    border: 1px solid #a2a2a2!important;
}
.border-g1:hover{
    border-color: rgba(0, 0, 0, 0.87) !important;

}

.MuiOutlinedInput-root.login-content .MuiOutlinedInput-notchedOutline{
    top: 0;
}
.MuiOutlinedInput-root.login-content .MuiOutlinedInput-notchedOutline .css-hdw1oc{
    float: left;
}

@media (max-width: 1499px){
    .date-count-content > div:first-of-type,
    .date-count-content > div:nth-of-type(2){
        width: 40%;
    }
    .date-count-content .MuiFormControl-root{
        width: 100%;
    }
    .date-count-content input#dobdate{
        width: 100% !important;
    }
}