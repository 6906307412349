.UsersPage .MuiTabs-flexContainer {
    justify-content: space-between !important;
}

.googleBtn>button{
    box-shadow: none !important;
    border: 2px solid #1990FC !important;
    padding: 7px 21px !important;
    min-width: 64px !important;
    width: 100% !important;
    border-radius: 5px !important;
    color: #13152a !important;
     justify-content: center !important;
    font-weight: 600 !important;
    background-color: white;
    padding: 15px 0 !important;
    display: flex;
    align-items: center;
}
/* .googleBtn>button >div{
    padding: 0 !important;
}
.googleBtn>button >span{
    padding: 10px 0 !important;
} */
@media(min-width: 700px){
    .UsersPage .MuiTabs-flexContainer {
        justify-content: space-around !important;
    }    
}