.patientlist {
  /* margin-right: 20px; */
}
.patientlist .MuiBadge-badge {
  width: 18px;
  height: 18px !important;
  border-radius: 50% !important;
}
.patientlist .MuiButtonBase-root.MuiButton-root {
  padding: 6px 10px;
}
.pastVisits .MuiTypography-h6,
.patientlist .MuiTypography-h6 {
  align-items: center;
  display: flex;
}
.patientlist .MuiButton-containedPrimary {
  min-width: fit-content !important;
  border-radius: 4px !important;
}
.patientlist .MuiButton-containedPrimary > a {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #ffffff;
}
.patientlist .MuiPaper-root,
.MuiCardContent-root {
  padding: 0px !important;
  margin: 0px !important;
}
/* #patientTableTitle {
    color: #2E2E2E;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
} */
#patientTableTitle,
#newpatientTitle,
#newvisitTitle {
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.darkTitle {
  color: #464646 !important;
}
.tabpanelContent .MuiInputBase-root.MuiOutlinedInput-root {
  /* padding: 0; */
  /* to remove padding of text-field */
  background-color: white;
  /* to give background for select field */
}

#newVisitTitle .MuiFormControl-root.MuiTextField-root,
#patientTableTitle .MuiFormControl-root.MuiTextField-root {
  width: 150px;
}
#newvisitTitle .MuiInputBase-colorPrimary,
#patientTableTitle .MuiInputBase-colorPrimary {
  background-color: white;
}
#newvisitTitle input,
#patientTableTitle input {
  width: 65%;
  background-color: white;
  border-radius: 5px !important;
  /* box-shadow: 0px 7px 30px 0px rgb(90 114 123 / 11%); */
}
#newVisitTitle input:focus-visible,
#newVisitTitle input:hover,
#patientTableTitle input:focus-visible {
  outline: none;
}
.patientPage .MuiInputBase-root.MuiOutlinedInput-root:hover {
  border: none !important;
  outline: none !important;
}
/* .pastVisits .MuiTableCell-body > h5, .pastVisits .MuiTableCell-body > h6,
.patientlist .MuiTableCell-body > h5, .patientlist .MuiTableCell-body > h6{
     font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
}
.pastVisits .MuiTableCell-head> h5
.patientlist .MuiTableCell-head> h5{
     font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
} */
.patientlistHeader.MuiToolbar-root {
  padding: 0 16px;
  /* padding-left: 16px;
    padding-right: 16px !important; */
  width: 100%;
  flex-direction: column-reverse;
}

.MuiTabs-indicator {
  margin-bottom: 11px;
  background-color: #1990fc !important;
}
.MuiButtonBase-root.MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #1990fc !important;
}
.patientlist .MuiTabs-flexContainer,
.patientTabs .MuiTabs-flexContainer {
  justify-content: space-around;
}

.newPatientCardBox.MuiBox-root {
  background-color: white;
  padding: 15px;
}
.patientPage .MuiPaper-root.MuiCard-root,
.patientDetailPage .MuiPaper-root.MuiCard-root,
.historyCard .MuiPaper-root.MuiCard-root,
.newPatientCard.MuiPaper-root.MuiCard-root {
  margin: 0px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(19, 83, 140, 0.15);
  padding: 15px;
}

.newPatientCard.MuiPaper-root.MuiCard-root {
  padding: 0px;
}
@media (max-width: 1200px) {
  .visitSidebar .MuiPaper-root.MuiDrawer-paper {
    background-color: transparent;
    box-shadow: none;
    min-height: 100%;
    overflow: auto;
    height: fit-content;
  }
}

@media (min-width: 1200px) {
  .newVisitCard.MuiPaper-root.MuiCard-root {
    padding: 0px;
    margin-left: 280px !important;
  }
  .newVisitPage .breadcrumbGrid {
    position: fixed;
    background: white;
    z-index: 1000;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .newVisitBox {
    padding-top: 100px !important;
  }
}

.medicalSummaryCard .MuiPaper-root.MuiCard-root {
  margin: 8px !important;
  border: 1px solid #e5e5e5;
  box-shadow: none;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  /* align-items: flex-start; */
  padding: 15px;
  gap: 10px;
}
.pastVisits .MuiPaper-root.MuiCard-root {
  padding: 0;
}
.patientlist .MuiPaper-root.MuiCard-root {
  margin: 0px;
  box-shadow: none;
  border-radius: 8px;
}
.MuiTabs-scroller {
  overflow: auto !important;
}

@media (min-width: 500px) {
  .tabpanelContent {
    padding: 32px;
    margin: 30px 0px;
  }
  .newPatientCardBox.MuiBox-root {
    padding: 20px;
  }
  .newPatientPage .MuiPaper-root.MuiCard-root {
    margin: 15px;
  }
  #newvisitTitle input {
    width: 76%;
  }
  #patientTableTitle input {
    width: 80%;
  }
  #newVisitTitle .MuiFormControl-root.MuiTextField-root,
  #patientTableTitle .MuiFormControl-root.MuiTextField-root {
    width: 200px;
  }
}
.tabpanelContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 20px;
  background: #ebf5ff;
  /* background: rgba(3, 201, 215, 0.12); */
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 15px 0px;
  /* box-shadow: 0px 7px 30px 0px rgb(90 114 123 / 11%) !important; */
  box-shadow: 0px 4px 4px rgba(19, 83, 140, 0.25);
}
.tabpanelContent > div {
  width: 100%;
}
.observationtabpanelContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  gap: 20px;
  background: #ebf5ff;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 15px 0px;
  box-shadow: 0px 1px 3px rgba(40, 53, 147, 0.25);
  border-radius: 15px;
}
.guardianPanelFields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
  flex-grow: 0;
}
.panelHeading {
  font-weight: 500 !important;
  font-size: 20px !important;
}
.tabpanelContent input {
  background-color: white !important;
  border-radius: 5px !important;
  border: 1px solid #a2a2a2 !important;
}
.visitDateField input {
  /* border: 2px solid #1990FC !important; */
  border-radius: 5px !important;
  padding: 5px;
  color: #1990fc;
}
.tabpanelContent textarea {
  background-color: white;
  /* padding: 8.5px 14px; */
}

.MuiRadio-root > span,
.MuiCheckbox-root > span {
  background-color: white;
}
.MuiRadio-root.Mui-checked > span,
.MuiCheckbox-root.Mui-checked > span {
  background-color: #1990fc !important;
}

.MuiPaper-root.MuiDrawer-paper > div.visitScrollbar {
  margin: 10px;
}

.visitDrawer .MuiPaper-root.MuiDrawer-paper > div {
  margin: 2px;
}
#patientTableTitle .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0;
  margin-left: 10px;
}
.registerButton.MuiButton-containedPrimary {
  min-width: fit-content !important;
}
#newvisitTitle > svg,
#patientTableTitle > svg {
  width: 18px;
  height: 18px;
  color: #bdbdbd;
  margin: 10px;
}
svg.searchIcon {
  width: 25px !important;
  height: 25px !important;
  color: white !important;
}
.panelHeading {
  display: flex;
  width: 100%;
  align-items: center;
}
.panelHeading .tabIcon {
  width: 40px;
  margin-right: 20px;
}
.guardianPanel > .panelAddButton {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
@media (max-width: 700px) {
  .tabpanelContent .MuiFormControl-root.MuiTextField-root {
    /* width: 100%; */
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .childrenBox.MuiBox-root {
    display: block !important;
    text-align: right !important;
    margin-top: 10px;
    margin-left: 15px;
  }
  .childrenBox
    .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select {
    text-align: left !important;
  }
  .breadcrumbGrid .MuiGrid-root.MuiGrid-container {
    padding: 0px 0px 15px 15px;
  }

  .visitSidebar .MuiPaper-root.MuiDrawer-paper > div {
    margin: 0;
  }
}
@media (min-width: 470px) {
  .patientlist .MuiToolbar-root {
    flex-direction: row;
  }
  #patientTableTitle {
    margin: 0;
  }
}
@media (min-width: 700px) {
  .visitDrawer > div {
    transition: none !important;
  }
  .newVisitBox .MuiTabs-flexContainer,
  .visitDetailBox .MuiTabs-flexContainer {
    justify-content: space-around;
  }
  .patientlist .MuiButton-containedPrimary > a {
    font-size: 16px;
  }
  .patientlist .MuiButtonBase-root.MuiButton-root {
    padding: 6px 18px;
  }
  #newVisitTitle .MuiFormControl-root.MuiTextField-root,
  #patientTableTitle .MuiFormControl-root.MuiTextField-root {
    width: 300px;
  }

  input#Referred_By,
  input#dobdate,
  input#expirationdate {
    width: 400px;
  }
  .tabpanelContent > div {
    width: unset;
  }
}

.dateDiv {
  position: relative;
  display: flex;
  align-items: center;
}
.calendaricon {
  position: absolute;
  right: 17px;
  width: 16px;
  /* color: #A2A2A2; */
}
.addpatientBtn.MuiButtonBase-root.MuiButton-root,
.visitTopButton.MuiButtonBase-root.MuiButton-root {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  background-color: #1990fc;
}
.visitTopButton.MuiButtonBase-root.MuiButton-root {
  line-height: 20px;
  /* float: right; */
}
.tabpanelContent .MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary,
.registerButton.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary {
  background-color: #1990fc;
}
.patientDetailPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  background: #ebf5ff;
  /* background: rgba(3, 201, 215, 0.12); */
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  background: #ebf5ff;
  box-shadow: 0px 2px 4px rgba(40, 53, 147, 0.25);
  /* margin: 15px 0px; */
  /* box-shadow: 0px 7px 30px 0px rgb(90 114 123 / 11%) !important; */
}

#newVisitTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.visitDrawer > div {
  position: unset;
}

.fileGrid .MuiPaper-root.MuiCard-root {
  margin: 10px;
  text-align: center;
}

.fileGrid .MuiPaper-root.MuiCard-root > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.fileGrid .MuiPaper-root.MuiCard-root > div:nth-child(2) {
  min-height: 80px;
  justify-content: space-around;
}
.fileGrid .MuiPaper-root.MuiCard-root > div:nth-child(3) {
  min-height: 60px;
  justify-content: end;
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
  /* dropdown height */
  height: fit-content;
  max-height: 200px;
  overflow: auto;
}
.tabpanelContent .mainTable {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}
.tabpanelContent .mainTable > div > div {
  border: 1px solid black;
  width: 100%;
}
.tabpanelContent .mainTable > div > {
  width: 100%;
}

.vaccineHistory .MuiTableCell-root {
  border: 1px solid black;
  padding: 0;
  vertical-align: top;
}
.vaccineHistory .MuiTableCell-root > .MuiTypography-root {
  padding: 16px;
}
.vaccineHistory .MuiTableCell-root > .borderdDiv.MuiTypography-root {
  border-bottom: 1px solid black;
}
.vaccineHistory .MuiTableCell-root > .borderdDiv.MuiTypography-root:last-child {
  border: none;
}

/* 1st column */
.vaccineHistory .MuiTableRow-root:nth-child(2n) .MuiTableCell-root:first-child {
  background-color: rgba(130, 79, 233, 0.1);
}
.vaccineHistory
  .MuiTableRow-root:nth-child(2n + 1)
  .MuiTableCell-root:first-child {
  background-color: rgba(163, 160, 163, 0.1);
}
/* 2nd column inner rows */
.vaccineHistory
  .MuiTableCell-root:nth-child(2)
  > .borderdDiv.MuiTypography-root:nth-child(2n) {
  background-color: rgba(101, 101, 245, 0.1);
}
.vaccineHistory
  .MuiTableCell-root:nth-child(2)
  > .borderdDiv.MuiTypography-root:nth-child(2n + 1) {
  background-color: rgba(233, 99, 186, 0.1);
}
/* 3rd 4th column */
.vaccineHistory
  .MuiTableCell-root:nth-child(3)
  > .borderdDiv.MuiTypography-root,
.vaccineHistory
  .MuiTableCell-root:nth-child(4)
  > .borderdDiv.MuiTypography-root {
  background-color: rgba(202, 194, 200, 0.1);
}

/* table head */
.vaccineHistory
  .MuiTableRow-root.MuiTableRow-head.MuiTableRow-root
  .MuiTableCell-root {
  background-color: #1990fc;
  color: white;
}

.breadcrimbsTitle > ol > li:nth-child(3) {
  color: #7cb8ee;
}
.breadcrimbsTitle > ol > li:nth-child(5) {
  color: #a1bad0;
}
/* #otherAllergen{
    padding: 10px;
} */
.visitScrollbar .MuiTypography-root {
  font-size: 14px;
  font-weight: 500;
}
.checkBoxGroup {
  padding: 0px 16.5px 14px 16.5px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(222, 227, 233);
}

.checkBoxGroup > div > div > .MuiTypography-root {
  margin-bottom: 0;
  margin-top: 10px;
}
.dietGrid .MuiFormControlLabel-root {
  align-items: flex-start;
  margin-bottom: 10px;
}
.dietGrid .MuiButtonBase-root.MuiCheckbox-root {
  padding-top: 0px;
}
.dietGrid .MuiButtonBase-root.MuiCheckbox-root > span,
.dietGrid .MuiButtonBase-root.MuiCheckbox-root > span::before {
  width: 1rem;
  height: 1rem;
}
.dietGrid .MuiTypography-root {
  line-height: 1rem;
  word-break: normal !important;
}
.MuiPaper-root.MuiDrawer-paper .NotesList .MuiBox-root {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  gap: unset !important;
}

.visitDrawer.MuiDrawer-docked {
  position: fixed !important;
}

.pastVisits .MuiTableCell-body > h5 {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 350px;
}
.visitTextArea {
  background: #ebf5ff;
  border: none;
  width: 100%;
  color: black;
  font-size: 14px;
  /* width: 300px; */
  min-height: 17px;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  /* word-break:break-all; */
  /* line-break: auto; */
}
.visitAutoSizeTextArea {
  background: #ebf5ff;
  border: none;
  width: 100%;
  color: black;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.summaryVaccineCard .MuiPaper-root.MuiCard-root.vaccineHistoryCard {
  margin: 0;
}
.summaryVaccineCard .MuiPaper-root.MuiDialog-paper {
  width: fit-content;
  max-width: 80%;
}
