body {
  background-color: gray;
  /* -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; */
}

.sigContainer {
  /* background-color: #fff; */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sigCanvas {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}
